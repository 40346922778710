<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <br />
    <div>
      <h1>Inserimento scuole e accordi</h1>
      <p>
        Si ricorda che è obbligatorio compilare la sezione "Inserimento scuola e
        accordi" prima della chiusura di ciascun corso
      </p>
    </div>

    <v-alert type="warning" elevation="2" v-if="anno > 2023">
      <p>
        Come previsto all’<b>allegato 3 al d.d. 10129/2024</b>, si segnala che è
        presente un <u>controllo bloccante</u> che non consente il
        <u>termine del corso</u> e la generazione degli attestati nei casi in
        cui:
      </p>
      <ul>
        <li>
          La <b>data di inizio dei corsi</b> con la Scuola o le Scuole incluse
          nel presente accordo sia antecedente alla data di firma dell’accordo
          stesso;
        </li>
        <li>
          <b>La data di fine dei corsi</b> con la Scuola o le Scuole incluse nel
          presente accordo sia successiva alla data di fine dell’accordo stesso.
        </li>
      </ul>
    </v-alert>

    <v-card-title>
      <h3 v-html="'Compila dati scuola e accordo'" />
    </v-card-title>
    <div v-if="programmaOrientamento">
      <alert-component
        v-if="operazione === 'lettura'"
        testo="Le informazioni dell'accordo scuola non possono essere modificate in
        quanto il pdf è stato inserito e al contempo esiste
        già un corso terminato per l'accordo  specifico."
        tipo-alert="info"
        class="white--text"
      >
        Le informazioni dell'accordo scuola non possono essere modificate in
        quanto il pdf dell'accordo scuola è stato inserito e al contempo esiste
        già un corso terminato per l'accordo scuola specifico.
      </alert-component>
      <v-form
        @submit="onSubmitScuolaAccordo"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-row>
            <v-col>
              <v-text-field
                label="CUP *"
                placeholder="CUP *"
                v-model="programmaOrientamento.cup"
                :rules="rulesForm.valueFormRules"
                outlined
                disabled
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Anno"
                v-model="this.annoScolastico"
                outlined
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                label="Numero corsi *"
                v-model="form.numeroCorsi"
                :rules="rulesForm.valueFormNumerici"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                placeholder="Numero alunni"
                label="Numero alunni *"
                v-model="form.numeroAlunni"
                :rules="rulesForm.valueFormNumerici"
                @keyup="controllaAlunni"
                :readonly="this.operazione === 'lettura'"
              ></v-text-field>
              <v-row>
                <v-col>
                  <small class="mt-0 orange--text" v-if="superatoTargetAlunni"
                    >L' inserimento di studenti oltre il target non assicura che
                    saranno finanziati se l’incremento non è approvato dal
                    MUR</small
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                label="Numero docenti da coinvolgere *"
                v-model="form.numeroDocenti"
                :rules="rulesForm.valueFormNumerici"
                :readonly="this.operazione === 'lettura'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-card-title><h5 v-html="'Università/AFAM'" /></v-card-title>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.pIvaStruttura"
                :rules="rulesForm.pIvaRules"
                label="CF/P.Iva Università/AFAM *"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.indirizzoStruttura"
                :rules="rulesForm.valueFormRules"
                label="Indirizzo Università/AFAM *"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.cittaStruttura"
                :rules="rulesForm.valueFormRules"
                label="Città Università/AFAM *"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.provinciaStruttura"
                :rules="rulesForm.valueFormRules"
                label="Provincia Università/AFAM *"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Nome Legale rappresentante"
                disabled
                v-model="legaleRappresentante.nomeRappresentante"
                outlined
                :readonly="this.operazione === 'lettura'"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-text-field
                label="Cognome Legale rappresentante"
                disabled
                v-model="legaleRappresentante.cognomeRappresentante"
                outlined
                :readonly="this.operazione === 'lettura'"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-text-field
                label="CF Legale rappresentante"
                v-model="legaleRappresentante.codiceFiscaleRappresentante"
                outlined
                disabled
                :readonly="this.operazione === 'lettura'"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card>

        <v-card>
          <v-card-title><h5 v-html="'Istituto Scolastico'" /></v-card-title>
          <v-card-subtitle>
            <p
              v-html="
                ' I campi città, provincia e indirizzo  vengono compilati in automatico quando si seleziona la scuola.'
              "
            />
          </v-card-subtitle>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="istituto"
                :rules="rulesForm.valueFormRules"
                :items="itemsIstituti"
                class="mx-4"
                flat
                hide-details
                item-text="text"
                item-value="value"
                placeholder="'Seleziona Istituto"
                solo-inverted
                @change="getIstitutoByCodice()"
                :readonly="this.operazione === 'lettura'"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Istituto di Riferimento"
                placeholder="Istituto di Riferimento"
                v-model="form.istitutoRiferimento"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="CF/P. Iva Istituto scolastico"
                placeholder="CF/P. Iva Istituto scolastico"
                v-model="form.pIvaIstituto"
                :error-messages="pIvaIstitutoErrors"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Città istituto scolastico *"
                placeholder="Città istituto scolastico *"
                v-model="form.comuneIstituto"
                :rules="rulesForm.valueFormRules"
                readonly
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Provincia istituto *"
                placeholder="Provincia Istituto scolastico *"
                v-model="form.provinciaIstituto"
                :rules="rulesForm.valueFormRules"
                readonly
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Indirizzo istituto *"
                placeholder="Indirizzo Istituto scolastico *"
                v-model="form.indirizzoIstituto"
                :rules="rulesForm.valueFormRules"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Nome dirigente scolastico *"
                placeholder="Nome dirigente scolastico *"
                :rules="rulesForm.valueFormRules"
                v-model="form.nomeDirigente"
                required
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Cognome dirigente scolastico *"
                placeholder="Cognome dirigente scolastico *"
                :rules="rulesForm.valueFormRules"
                v-model="form.cognomeDirigente"
                required
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="CF dirigente scolastico *"
                placeholder="CF dirigente scolastico *"
                :rules="rulesForm.cfRules"
                v-model="form.codiceFiscaleDirigente"
                required
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-card-title>
            <h3 v-html="'Carica accordo'" />
          </v-card-title>
          <v-card-subtitle>
            <p
              v-html="
                'Caricare per ogni scuola il relativo accordo scuola - università firmato digitalmente da entrambe le parti. Il file dovrà essere un .pdf'
              "
            />
          </v-card-subtitle>
          <v-row>
            <v-col cols="6">
              <v-text-field
                placeholder="Istituzione scolastica non inserita"
                :value="labelIstitutoVisualizzazione"
                :readonly="this.operazione === 'lettura'"
              />
            </v-col>
          </v-row>
          <v-row v-if="anno > 2023">
            <v-col cols="6">
              <v-text-field
                type="date"
                v-model="form.dataInizioAccordo"
                label="Data Inizio Accordo"
                prepend-icon="mdi-calendar"
                :rules="getValidazioneDataAccordo(1)"
                @change="getValidazioneDataAccordo(1)"
                :readonly="this.operazione === 'lettura'"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on"
                    v-bind="attrs"
                    v-model="form.dataFineAccordo"
                    type="date"
                    label="Data fine accordo"
                    prepend-icon="mdi-calendar"
                    :rules="getValidazioneDataAccordo(2)"
                    @change="getValidazioneDataAccordo(2)"
                  ></v-text-field>
                </template>
                <span>
                  Tutte le lezioni relative ai corsi censiti nell'ambito di tale
                  accordo devono essere svolte tra la data di inizio e la data
                  di fine dell'accordo. L'accordo può avere una durata pari
                  all'a.s. oppure essere valido fino al termine del periodo.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"> </v-col>
            <v-col cols="6">
              <v-file-input
                v-if="anno < 2024"
                accept="application/pdf"
                :placeholder="placeholderFileInput"
                v-model="pdfAccordo"
                :disabled="!istituto || this.operazione === 'lettura'"
                @change="checkPdfCaricato = true"
              />
              <!-- anno a partire dal 2024, il caricamento del pdf va in una modale attivabile se e solo se i dati dell'accordo sono già presenti in db-->
              <div v-else>
                <div v-if="scuolaAccordoOriginale" class="mx-6">
                  <ModalCaricamentoAccordo
                    v-if="scuolaAccordoOriginale"
                    :accordo="scuolaAccordoOriginale"
                    :pdf-da-caricare="operazione !== 'inserimento'"
                    @refresh="refreshAll"
                  ></ModalCaricamentoAccordo>
                </div>
                <div v-else>
                  <span class="red--text font-weight-bold">
                    Si potrà caricare il pdf solo dopo aver creato l'accordo
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="form.dataFirmaAccordo && form.fileAccordo">
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-calendar"
                label="Data Firma Accordo"
                v-model="form.dataFirmaAccordo"
                type="date"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="mx-6">
                <a @click="downloadPDF(form, form.fileAccordo.nomeOriginale)">
                  <v-icon color="primary">mdi-paperclip</v-icon> Download pdf
                  accordo
                </a>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-row>
            <v-col>
              <v-btn
                color="#06c"
                @click="dialogSalva = true"
                dark
                v-html="'Conferma'"
                :disabled="!valid || this.operazione === 'lettura'"
              />
              <dialog-conferma
                @callback="onSubmitScuolaAccordo"
                :dialog.sync="dialogSalva"
                @close-dialog="dialogSalva = false"
              />
              <v-snackbar
                v-model="settingsSnackbar.visible"
                height="100"
                width="500"
                multi-line
                right
                :color="settingsSnackbar.color"
              >
                {{ settingsSnackbar.text }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="settingsSnackbar.visible = false"
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
            <v-col>
              <v-alert
                v-if="!checkPdfCaricato && !pdfAccordo"
                type="warning"
                dense
                width="550"
                class="alert"
              >
                Attenzione! Il pdf non è stato selezionato. <br />
                Non è obbligatoria, ma dovrà essere caricato entro la fine del
                corso.
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </div>
    <div v-else>
      <v-alert
        class="alert"
        width="800"
        type="warning"
        v-html="
          'Non è possibile aggiungere un accordo-scuola se non hai creato un <b> PROGRAMMA DI ORIENTAMENTO</b>.'
        "
      />
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";
import istitutiScolasticiRepository from "@/api/istituto/IstitutiScolasticiRepository";
import { mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import CodiceFiscale from "codice-fiscale-js";
import RichiesteRepository from "@/api/istituto/RichiesteRepository";
import DialogConferma from "@/components/DialogConferma.vue";
import AlertComponent from "@/components/Common/AlertComponent.vue";
import ModalCaricamentoAccordo from "@/components/ScuoleAccordi/ModalCaricamentoAccordo.vue";

export default {
  name: "AggiugiScuolaAccordiView",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ModalCaricamentoAccordo,
    AlertComponent,
    BreadcrumbComponent,
    DialogConferma,
  },
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idAccordo: {
      type: Number,
      required: false,
    },
    operazione: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newCodiceMeccanografico: false,
    totaleAlunniAccordi: 0,
    scuoleAccordi: [],
    superatoTargetAlunni: false,
    loadingPage: false,
    valid: true,
    checkPdfCaricato: false,
    itemsIstituti: [],
    labelIstitutoVisualizzazione: null,
    programmaOrientamento: null,
    istituto: null,
    istitutoForm: null,
    strutturaInfo: null,
    annoScolastico: null,
    legaleRappresentante: {
      nomeRappresentante: null,
      cognomeRappresentante: null,
      codiceFiscaleRappresentante: null,
    },
    settingsSnackbar: {
      visible: false,
      text: null,
      color: null,
    },
    form: {
      annoScolastico: null,
      struttura: null,
      numeroCorsi: 1,
      numeroAlunni: 1,
      numeroDocenti: 1,
      pIvaStruttura: null,
      cittaStruttura: null,
      provinciaStruttura: null,
      indirizzoStruttura: null,
      indirizzoIstituto: null,
      provinciaIstituto: null,
      comuneIstituto: null,
      pIvaIstituto: null,
      nomeDirigente: null,
      cognomeDirigente: null,
      codiceFiscaleDirigente: null,
      istitutoRiferimento: null,
      dataInizioAccordo: null,
      dataFineAccordo: null,
    },
    pdfAccordo: null,
    placeholderFileInput: "File PDF Accordo-Scuola",
    rulesForm: {
      valueFormNumerici: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) =>
          (!!v && v >= 1) ||
          "Il valore del campo deve essere un valore positivo",
      ],
      pIvaRules: [
        (v) => !!v || "E' obbligatoria la compilazione del campo",
        (v) =>
          (v && v.length === 11) ||
          "La partita IVA deve contenere 11 caratteri",
      ],
      cfRules: [
        (v) => !!v || " E' obbligatoria la compilazione del campo",
        (v) =>
          (v && v.length === 16) ||
          "Codice fiscale non valido. Deve essere di 16 caratteri",
      ],
      valueFormRules: [
        (v) => !!v || "E' obbligatoria la compilazione del campo",
      ],
    },
    dialogSalva: false,
    visualizzazioneForm: false,
    scuolaAccordoOriginale: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
    loading: function () {
      return this.loadingPage;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Scuole Accordi",
            disabled: false,
            to: `/scrivania/${this.capofila}/scuole-accordi`,
            exact: true,
            link: true,
          },
          {
            text: "Aggiungi scuola accordo",
            disabled: true,
            to: `/scrivania/${this.capofila}/scuole-accordi/`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
    // eslint-disable-next-line vue/return-in-computed-property
    pIvaIstitutoErrors() {
      if (this.form.pIvaIstituto && this.form.pIvaIstituto !== "") {
        return this.form.pIvaIstituto.length !== 11
          ? "La partita Iva, se compilata dev avere esattamete 11 caratteri"
          : "";
      }
      return "";
    },
  },
  watch: {
    idStrutture() {
      this.initializeView();
    },
    anno() {
      this.initializeView();
    },
  },
  created() {
    this.initializeView();
  },
  methods: {
    async initializeView() {
      this.loadingPage = false;
      this.annoScolastico = this.anno + "/" + (this.anno + 1);
      const idStrutture = this.$store.state.idStrutture;
      const anno = this.$store.state.anno;
      await this.getProgrammaOrientamento(idStrutture, anno);
      if (this.operazione === "inserimento") {
        await this.getSingolaStruttura(idStrutture);
      } else {
        await this.getSingoloAccordo(idStrutture, anno);
      }
    },
    async getSingolaStruttura(idStrutture) {
      IstitutoRepository.getSingolaStruttura(idStrutture).then((data) => {
        let infoUniversita = data[0];
        this.strutturaInfo = infoUniversita;
        infoUniversita != null
          ? ((this.form.indirizzoStruttura = infoUniversita.via),
            (this.form.provinciaStruttura = infoUniversita.provincia),
            (this.form.cittaStruttura = infoUniversita.citta),
            (this.form.pIvaStruttura = infoUniversita.partitaIva))
          : ((this.form.indirizzoStruttura = null),
            (this.form.provinciaStruttura = null),
            (this.form.cittaStruttura = null),
            (this.form.pIvaStruttura = null));
      });
    },
    async getIstitutiScolastici() {
      istitutiScolasticiRepository.getIstituti().then((data) => {
        this.itemsIstituti = data;
      });
      this.loadingPage = true;
    },
    async getSingoloAccordo(idStrutture, anno) {
      ScuoleAccordoRepository.getSingoloAccordo(
        idStrutture,
        this.idAccordo,
        anno
      ).then((data) => {
        this.scuolaAccordoOriginale = data;
        this.form = data;
        this.form.dataInizioAccordo = this.form.dataInizioAccordo
          ? this.form.dataInizioAccordo.split("T")[0]
          : null;
        this.form.dataFineAccordo = this.form.dataInizioAccordo
          ? this.form.dataFineAccordo.split("T")[0]
          : null;
        this.form.dataFirmaAccordo = this.form.dataFirmaAccordo
          ? this.form.dataFirmaAccordo.split("T")[0]
          : null;
        console.log(this.form);
        this.istituto = data.istituto.id;
        // settaggio valori programma di roientamento e legale rappresentante
        this.programmaOrientamento = data.programmaOrientamento;
        this.legaleRappresentante = data.legaleRappresentante;
        // settaggio valori istituto scolastico
        this.configuraCampiIstitutoScolastico(data.istituto);
        if (data.fileAccordo) {
          this.checkPdfCaricato = true;
          this.placeholderFileInput = data.fileAccordo.nomeOriginale;
        }
      });
      this.loadingPage = true;
    },
    refreshAll() {
      this.initializeView();
    },
    async getIstitutoByCodice() {
      let codiceIstituto = this.istituto;
      istitutiScolasticiRepository
        .getIstitutoScolasticoByCodice(codiceIstituto)
        .then((data) => {
          this.configuraCampiIstitutoScolastico(data);
        });
      this.newCodiceMeccanografico = true;
    },
    async getProgrammaOrientamento(idStrutture, anno) {
      ProgrammiRepository.getProgrammi(idStrutture, anno, this.capofila).then(
        (data) => {
          if (data.length > 0) {
            this.visualizzazioneForm = true;
            this.programmaOrientamento = data[0];
            this.form.cup = data[0].cup;
            let idProgramma = data[0].id;
            this.getScuoleAccordi();
            this.getIstitutiScolastici();
            this.getLegaleRappresentanteAttivo(idStrutture, idProgramma);
          } else {
            this.programmaOrientamento = null;
            this.visualizzazioneForm = false;
          }
        }
      );
      this.loadingPage = true;
    },
    async getLegaleRappresentanteAttivo(idStrutture, idProgramma) {
      RichiesteRepository.getLegaleRappresentante(
        idStrutture,
        idProgramma
      ).then((data) => {
        if (data) {
          this.legaleRappresentante = data;
        }
      });
    },
    onSubmitScuolaAccordo() {
      if (this.$refs.form.validate()) {
        let anno = this.$store.state.anno;
        let struttura = this.$store.state.idStrutture;
        var codiceFiscale = null;
        try {
          // eslint-disable-next-line no-unused-vars
          codiceFiscale = new CodiceFiscale(this.form.codiceFiscaleDirigente);
        } catch (err) {
          return (this.settingsSnackbar = {
            visible: true,
            text: "Codice fiscale associato al dirigente di istituto non valido!",
            color: "red accent-2",
          });
        }
        this.setupForm(anno, struttura);
        let formScuolaAccordo = JSON.stringify(this.form);
        const formData = new FormData();
        formData.append("scuola_accordo", formScuolaAccordo);
        formData.append("istituto", this.istitutoForm.id);
        formData.append("pdf_accordo", this.pdfAccordo);
        if (this.operazione === "inserimento") {
          formData.append(
            "legale_rappresentante",
            this.legaleRappresentante.id
          );
        }
        if (this.operazione === "modifica") {
          if (this.newCodiceMeccanografico) {
            this.rivalidazioneCf();
          }
          ScuoleAccordoRepository.modificaAccordo(
            struttura,
            anno,
            this.idAccordo,
            formData
          ).then((data) => {
            if (data) {
              this.initializeView();
              /*return this.$router.push(
                `/scrivania/${this.capofila}/scuole-accordi`
              );*/
            }
          });
        } else {
          ScuoleAccordoRepository.caricaScuolaAccordo(
            struttura,
            anno,
            this.capofila,
            formData
          )
            .then((data) => {
              if (data) {
                return this.$router.push(
                  `/scrivania/${this.capofila}/scuole-accordi`
                );
              }
            })
            .catch((reason) => {
              console.log(reason);
              reason.code === "ERR_BAD_REQUEST"
                ? (this.settingsSnackbar = {
                    visible: true,
                    text: reason.response.data,
                    color: "red accent-2",
                  })
                : (this.settingsSnackbar = {
                    visible: true,
                    textAlert:
                      "Un errore è stato riscontrato nella gestione della richiesta Si prega di contattare il supporto.",
                    color: "red accent-2",
                  });
            });
        }
      }
    },
    async rivalidazioneCf() {
      await ScuoleAccordoRepository.getRivalidazioneCf(
        this.idStrutture,
        this.idAccordo
      );
    },
    setupForm(anno, struttura) {
      delete this.form.indirizzoIstituto;
      delete this.form.provinciaIstituto;
      delete this.form.comuneIstituto;
      delete this.form.istitutoRiferimento;

      if (this.operazione === "modifica") {
        delete this.form.istituto;
        delete this.form.fileAccordo;
        delete this.form.programmaOrientamento;
      }
      if (this.form.codiceFiscaleDirigente) {
        this.form.codiceFiscaleDirigente =
          this.form.codiceFiscaleDirigente.toUpperCase();
      }
      // assegna i valori dello state alla form
      this.form.annoScolastico = anno;
      this.form.struttura = struttura;
    },
    checkPartitaIvaIstituto() {
      if (this.form.pIvaIstituto && this.form.pIvaIstituto !== "") {
        return this.form.pIvaIstituto.length === 11;
      }
      return true;
    },
    controllaAlunni() {
      let totaleAlunniProvvisorio =
        Number(this.form.numeroAlunni) + Number(this.totaleAlunniAccordi);
      if (
        totaleAlunniProvvisorio >
        this.programmaOrientamento.numeroAlunniCoinvolti
      ) {
        this.superatoTargetAlunni = true;
      } else {
        this.superatoTargetAlunni = false;
      }
    },
    async getScuoleAccordi() {
      this.scuoleAccordi = await ScuoleAccordoRepository.getScuoleAccordi(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      if (this.scuoleAccordi.length === 0) {
        this.totaleAlunniAccordi =
          this.programmaOrientamento.numeroAlunniCoinvolti;
      } else {
        this.totaleAlunniAccordi = this.scuoleAccordi.reduce(
          (accumulator, object) => {
            return accumulator + object.numeroAlunni;
          },
          0
        );
      }
    },
    async configuraCampiIstitutoScolastico(istituto) {
      this.labelIstitutoVisualizzazione = istituto.denominazioneScuola;
      this.istitutoForm = istituto;
      this.form.comuneIstituto = istituto.descrizioneComune;
      this.form.provinciaIstituto = istituto.provincia;
      this.form.indirizzoIstituto = istituto.indirizzoScuola;
      this.form.istitutoRiferimento = istituto.codiceIstitutoRiferimento
        ? istituto.codiceIstitutoRiferimento
        : "Non disponibile";
    },
    async downloadPDF(scuolaAccordo, nomeFileOriginale) {
      return ScuoleAccordoRepository.downloadPDFAccordo(
        this.idStrutture,
        scuolaAccordo.id,
        nomeFileOriginale
      );
    },
    getValidazioneDataAccordo(tipoData) {
      let data = null;
      let dataInizio = null;
      switch (tipoData) {
        case 1:
          data = new Date(this.form.dataInizioAccordo);
          return [
            (v) => !!v || "Il campo deve essere valorizzato",
            () => {
              // la data non deve essere antecedende al 1 di settembre 2024;ù
              let minDate = new Date("2024-09-01");
              return minDate > data
                ? "La data non deve essere antecedente al 1 di settembre 2024"
                : true;
            },
          ];

        case 2:
          data = new Date(this.form.dataFineAccordo);
          dataInizio = new Date(this.form.dataInizioAccordo);
          return [
            (v) => !!v || "Il campo deve essere valorizzato",
            () => {
              if (dataInizio && dataInizio > data) {
                return "La data di inizio non può essere successiva alla data di fine";
              }
            },
            () => {
              // la data non deve essere antecedende al 1 di settembre 2024;
              let minDate = new Date("2024-09-01");
              let maxDate = new Date("2026-04-30");
              return minDate > data || data > maxDate
                ? "La data non deve essere antecedente al 1 di settembre 2024 oppure posteriore al 30 aprile del 2026"
                : true;
            },
          ];
      }
    },
  },
};
</script>

<style scoped>
.v-text-field,
.v-select,
.v-file-input {
  padding: 0 5% 0 5%;
  width: 80%;
}

.v-btn {
  margin: 3%;
  width: auto;
  height: 5%;
  color: #dddddd;
  background-color: #06c;
}

.alert {
  text-align: center;
}
</style>
