<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn text class="blue--text" v-bind="attrs" v-on="on"
              ><v-icon class="mx-2"> mdi-loupe </v-icon>
              Aggiungi studente
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center"
          >Aggiungi studente
        </v-card-title>

        <v-card-text>
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nome *"
                    v-model="studente.nome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraNome()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Cognome *"
                    v-model="studente.cognome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraCognome()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Codice Fiscale *"
                    v-model="studente.cf"
                    :rules="validazioneCF()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercase()"
                    @blur="datiFromCF(studente.cf)"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    v-model="studente.residenzaEstera"
                    label="Residenza estera"
                    type="checkbox"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="studente.comuneNascitaDescr"
                    label="Comune di nascita *"
                    disabled
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!studente.residenzaEstera">
                  <v-autocomplete
                    :items="listaComuni"
                    v-model="studente.comuneResidenzaId"
                    item-text="name_it"
                    item-value="id"
                    label="Comune di residenza *"
                    :rules="validazioneCampoResidenza(studente.residenzaEstera)"
                    @input="validateField"
                    @change="validateField"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="studente.dataNascita"
                    label="Data di nascita *"
                    type="date"
                    disabled
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="studente.residenzaEstera">
                  <v-autocomplete
                    :items="listaStati"
                    v-model="studente.nazioneResidenzaId"
                    item-text="name_it"
                    item-value="istat_id"
                    label="Nazione di residenza *"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="studente.classe"
                    :items="classiStudente"
                    item-text="descrizione"
                    item-value="id"
                    label="Classe *"
                    :rules="validazioneCampoNecessario()"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="studente.genere"
                    :items="generiStudente"
                    item-text="descrizione"
                    item-value="id"
                    disabled
                    label="Genere *"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row v-if="anno !== 2022">
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="studente.istituto"
                    :items="accordi"
                    :item-text="testoCompleto"
                    item-value="istituto.id"
                    label="Codice Scuola *"
                    :rules="validazioneCampoNecessario()"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="blue darken-1" text @click="dialog = false">
            Annulla
          </v-btn>
          <v-btn class="primary" text @click="dialogSalva = true">
            Inserisci studente
          </v-btn>
        </v-card-actions>
        <dialog-conferma
          @callback="createStudenteCorso"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />

        <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogConferma from "@/components/DialogConferma";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import { mapState } from "vuex";
export default {
  name: "DialogAggiungiStudente",
  components: {
    DialogConferma,
  },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
    updateCorso: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  data: () => ({
    corso: null,
    accordi: [],
    isEstero: false,
    under12: false,
    dialog: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    generiStudente: [],
    classiStudente: [],
    listaComuni: [],
    listaStati: [],
    comune: [],
    studente: {
      nome: null,
      cognome: null,
      dataNascita: null,
      comuneNascitaId: null,
      comuneResidenzaId: null,
      comuneNascitaDescr: null,
      comuneResidenzaDescr: null,
      nazioneResidenzaId: null,
      nazioneResidenzaDescr: null,
      cf: null,
      genere: null,
      classe: 0,
      estero: false,
      residenzaEstera: false,
      istituto: null,
    },
    studenteDefault: {
      nome: null,
      cognome: null,
      dataNascita: null,
      comuneNascitaId: null,
      comuneResidenzaId: null,
      comuneNascitaDescr: null,
      comuneResidenzaDescr: null,
      nazioneResidenzaId: null,
      nazioneResidenzaDescr: null,
      cf: null,
      genere: null,
      classe: 0,
      estero: false,
      residenzaEstera: false,
      istituto: null,
    },
  }),
  watch: {
    updateCorso() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      /*if (this.idStrutture != null && this.idCorso != null) {
        this.getGeneriStudente();
      }*/
      this.getGeneriStudente();
      this.getClassiStudente();
      this.getListaComuni();
      this.getListaStati();
      this.getCorso();
    },
    uppercase() {
      this.studente.cf = this.studente.cf.toUpperCase();
    },
    uppercasePrimaLetteraNome() {
      if (this.studente.nome) {
        this.studente.nome =
          this.studente.nome[0].toUpperCase() + this.studente.nome.slice(1);
      }
    },
    uppercasePrimaLetteraCognome() {
      if (this.studente.cognome) {
        this.studente.cognome =
          this.studente.cognome[0].toUpperCase() +
          this.studente.cognome.slice(1);
      }
    },
    async datiFromCF(cf) {
      let idStrutture = this.$store.state.idStrutture;
      let anagrafica = await CorsiRepository.getAnagraficaStudente(
        idStrutture,
        cf
      );
      this.studente.dataNascita = anagrafica.subject.birthDate.date;
      let dataNascitaFormattata = this.studente.dataNascita.split(" ");
      this.studente.dataNascita = dataNascitaFormattata[0];
      this.controlloUnder12(this.studente.dataNascita);
      this.studente.genere = anagrafica.subject.gender === "M" ? 1 : 2;
      if (anagrafica.isEstero) {
        this.isEstero = true;
        this.studente.estero = true;
        this.studente.comuneNascitaId = anagrafica.comune.istat_id;
        this.studente.comuneNascitaDescr = anagrafica.comune.name_it;
      } else {
        this.isEstero = false;
        this.studente.comuneNascitaId = anagrafica.comune.id;
        this.studente.comuneNascitaDescr = anagrafica.comune.name_it;
      }
    },
    // vincolo età under 12 AMSMURUNIV-8914
    controlloUnder12(dataNascitaStudente) {
      const dataDiNascita = new Date(dataNascitaStudente);
      const dataAttuale = new Date();
      const differenzaAnni =
        dataAttuale.getFullYear() - dataDiNascita.getFullYear();
      // Verifica se lo studente ha almeno 12 anni
      if (differenzaAnni < 12) {
        this.under12 = true;
      } else if (differenzaAnni === 12) {
        // Se lo studente ha esattamente 12 anni, verifica giorno e mese
        this.under12 =
          dataAttuale.getMonth() < dataDiNascita.getMonth() ||
          (dataAttuale.getMonth() === dataDiNascita.getMonth() &&
            dataAttuale.getDate() < dataDiNascita.getDate());
      } else {
        this.under12 = false;
      }
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },
    validazioneCampoResidenza(estero) {
      return [(v) => (!!v && !estero) || "Campo richiesto"];
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    async getGeneriStudente() {
      this.generiStudente = await CorsiRepository.getGeneriStudente();
    },
    async getClassiStudente() {
      this.classiStudente = await CorsiRepository.getClassiStudente();
    },
    async getListaComuni() {
      this.listaComuni = await CorsiRepository.getListaComuniAttivi();
    },
    async getListaStati() {
      this.listaStati = await CorsiRepository.getListaStatiAttivi();
    },
    async getCorso() {
      this.corso = await CorsiRepository.getCorsoOrientamento(
        this.idStrutture,
        this.anno,
        this.idCorso
      );
      this.accordi = this.corso.accordi ?? [];
      if (this.corso.accordo && this.anno === 2022) {
        this.accordi.push(this.corso.accordo);
        this.studente.istituto = this.corso.accordo.istituto.id;
      }
    },
    testoCompleto(item) {
      return `${item.istituto.codiceScuola} -- ${item.istituto.denominazioneScuola} -- ${item.istituto.tipologiaGradoIstruzioneScuola} -- ${item.istituto.regione} -- ${item.istituto.descrizioneComune}`;
    },
    async createStudenteCorso() {
      // Vincolo dei 12 anni AMSMURUNIV-8914
      if (this.under12) {
        this.snackbarText =
          "ATTENZIONE: non è possibile inserire uno studente che abbia meno di 12 anni ";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      } else {
        this.validateField();
      }
      if (this.validate) {
        let studente = { ...this.studente };
        let idStrutture = this.$store.state.idStrutture;

        // post file to server
        const formData = new FormData();
        formData.append("studente", JSON.stringify(studente));

        try {
          this.nuovoStudente = await CorsiRepository.createStudente(
            idStrutture,
            formData
          );
          await CorsiRepository.createStudenteCorso(
            idStrutture,
            this.nuovoStudente.id,
            this.idCorso,
            this.studente.classe,
            this.studente.istituto
          );
          this.$emit("studente-aggiunto");
          this.studente = { ...this.studenteDefault };
          this.snackbarText = "Studente inserito con successo";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.validate = true;
          this.initialize();
          this.dialog = false;
        } catch (e) {
          console.log(e);
          /* Controlli su inserimento singolo studente e gestione errori 409
              1- se è presente in DB ma non appartiene a nessun corso -> return errore specifico gestito FE con valore: 1
              2- se il corso a cui appartiene NON risulta terminato -> return errore specifico gestito FE con valore : 2
              3- se lo studente ha un attestato firmato -> return errore specifico gestito FE con valore : 3
              4- se lo studente ha superato il 70% delle ore (10.5 ore) -> return errore specifico gestito FE con valore : 4
           */
          if (e.response.status === 403) {
            this.snackbarText =
              "ATTENZIONE: non è possibile inserire uno studente che abbia meno di 12 anni";
            this.snackbarColor = "red accent-2";
          }
          if (e.response.status === 409) {
            /*  non più utilizzato;*/
            /*if (e.response.data === 1) {
              this.snackbarText =
                "ATTENZIONE: Il codice fiscale inserito è già presente. Contattare il referente di programma o l'istituto scolastico che ha stipulato l'accordo";
              this.snackbarColor = "red accent-2";
            }*/
            if (e.response.data === 2) {
              this.snackbarText =
                "ATTENZIONE: Non è possibile re-inserire uno studente di un corso non ancora terminato!";
              this.snackbarColor = "red accent-2";
            }
            if (e.response.data === 3) {
              this.snackbarText =
                "ATTENZIONE: Non è possibile re-inserire uno studente in possesso di un certificato firmato!";
              this.snackbarColor = "red accent-2";
            }
            if (e.response.data === 4) {
              this.snackbarText =
                "ATTENZIONE: Non è possibile re-inserire uno studente che ha già frequentato più del 70% delle ore di lezione di un corso";
              this.snackbarColor = "red accent-2";
            }
          } else {
            this.snackbarText =
              "Problema durante il salvataggio, contattare il supporto se il problema persiste";
            this.snackbarColor = "red accent-2";
          }
        }
        this.snackbar = true;
      }
    },
  },
};
</script>
